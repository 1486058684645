import React, { useEffect, useRef } from 'react';
import useInView from '../../../components/inView/useInView';

const VImgSection = () => {
    const [setRef, inView] = useInView({
        threshold: 0.1, // Trigger when the section is at least 10% in view
    });
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.currentTime = 10; // Start at 10 seconds
            videoRef.current.pause();

            // Optional: Accurate seeking on load
            const handleCanPlay = () => {
                videoRef.current.currentTime = 1;
                videoRef.current.pause();
            };

            videoRef.current.addEventListener('canplay', handleCanPlay, { once: true });

            // Cleanup function
            return () => {
                videoRef.current.removeEventListener('canplay', handleCanPlay);
            };
        }
    }, []); // Empty dependency array means this runs once on mount

    return (
        <section className={`VImgSection ${inView ? 'in-view' : ''}`} ref={setRef}>
            <section className='v-img-section d-flex justify-content-center align-items-center my-6'>
                {inView && (
                    <div className="card-video-container">
                        <video controls preload="auto" className="card-video" ref={videoRef}>
                            <source src="assets/video/axn_lq_V3.mp4" type="video/mp4" media="(max-width: 480px)" />
                            <source src="assets/video/axn_mq_V2.mp4" type="video/mp4" media="(min-width: 481px)" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                )}
            </section>
        </section>
    );
}

export default VImgSection;
