// HelpContent.jsx
import React, { useState } from 'react';
import './HelpContent.css'; // Create a new CSS file or reuse existing styles

const VideoTutorial = ({ src, title }) => (
  <div className="video-tutorial">
    <h4>{title}</h4>
    <video width="100%" controls preload="auto">
      <source src={src} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
);

const HelpContent = () => {
  const [currentVideo, setCurrentVideo] = useState(null);

  const videos = [
    { title: "Alice Help", src: "/assets/video/AliceHelp.mp4" },
    { title: "Hannah Help", src: "/assets/video/HannahHelp.mp4" },
    { title: "Payment Help", src: "/assets/video/PayHelp.mp4" },
    { title: "Dori Help", src: "/assets/video/TranscriptionHelp.mp4" },
    { title: "Ray Help", src: "/assets/video/YouTubeHelp.mp4" },
  ];

  return (
    <div className="helpContent-body">
      <h2>AXN.ai – Help Center</h2>
          
          <section>
            <h3>Welcome</h3>
            <p>Welcome to the AXN.ai Help Center. Explore the comprehensive capabilities of our AI-driven solutions—Alice, Hannah, and our advanced transcription and summarization services. At AXN.ai, we are committed to facilitating an efficient and productive experience for our users.</p>
          </section>
          
          <section>
            <h3>Getting Started</h3>
            <p>Embark on your journey with AXN.ai by setting up your account, navigating the dashboard, and leveraging our services to enhance your productivity.</p>
          </section>
          
          <section>
            <h3>Guides and Tutorials</h3>
            <p>Access step-by-step guides for an optimized workflow:</p>
            <ul>
              <li>Alice: Automate meeting notes and insights with our AI-powered assistant.</li>
              <li>Hannah: Facilitate communication across over 130 languages directly through WhatsApp.</li>
              <li>Dori: Manage your transcription and summarization files with ease.</li>
              <li>Ray: Transcribe YouTube videos for enhanced interaction and learning.</li>
              <li>Content Engagement: Engage with your content through interactive queries and insights.</li>
            </ul>
          </section>
          <section>
            <h3>Video Learning</h3>
            <p>Explore our functionalities through engaging video tutorials.</p>
            <div className="video-tutorials-list">
              {videos.map((video, index) => (
                <button 
                  key={index} 
                  onClick={() => setCurrentVideo(video)}
                  className="video-tutorial-button"
                >
                  {video.title}
                </button>
              ))}
            </div>
            {currentVideo && (
              <VideoTutorial key={currentVideo.src} src={currentVideo.src} title={currentVideo.title} />
            )}
          </section>
          
          <section>
            <h3>Troubleshooting</h3>
            <p>Effortlessly navigate common issues for a seamless AXN.ai experience.</p>
          </section>
          
          
          
          <section>
            <h3>Feature Requests</h3>
            <p>Contribute to our growth by suggesting new features or voting on community ideas.</p>
          </section>
          
          <section>
            <h3>FAQs</h3>
            <div className="helpModal-faqSection">
              <p><strong>Q: What is AXN.ai?</strong></p>
              <p>A: AXN.ai is an advanced AI-powered platform offering a suite of services including media recording, transcription, translation, and summarization. It's designed to cater to a broad audience, from students and professionals to users of all cultural backgrounds and languages.</p>

              <p><strong>Q: Who is Alice?</strong></p>
              <p>A: Alice is an AI-powered digital meeting assistant that attends online meetings on your behalf. She can record minutes, transcribe dialogue, summarize key points, and translate the content into over 130 languages.</p>

              <p><strong>Q: How do I start using Alice for my meetings?</strong></p>
              <p>A: Simply provide Alice with the meeting ID and passcode, and she'll attend, take notes, transcribe, and summarize the meeting.</p>

              <p><strong>Q: Who is Hannah?</strong></p>
              <p>A: Hannah is an AI-powered assistant available on WhatsApp, designed to be an everyday companion that can understand and communicate in over 130 languages. You can ask Hannah any questions or queries just like you would a friend. You can even send it a voice clip and it will respond.</p>

              <p><strong>Q: Can I use Hannah for language translations?</strong></p>
              <p>A: Yes, Hannah is equipped to handle over 130 languages. You can send your queries as voice or text messages via WhatsApp.</p>

              <p><strong>Q: How does AXN.ai's media upload feature work?</strong></p>
              <p>A: With our media upload feature, you can upload text, Word, or PDF files to our platform. AXN.ai can translate, transcribe, and summarize these files. You also have the ability to engage with your document transcriptions to find specific information.</p>

              <p><strong>Q: Can AXN.ai process YouTube videos?</strong></p>
              <p>A: Yes, AXN.ai offers a feature where you can provide a YouTube video link, and our service will extract the video to transcribe, summarize, and translate the content. You can also ask detailed questions about the transcript's subject matter.</p>

              <p><strong>Q: How do I question or interact with a document that I've uploaded?</strong></p>
              <p>A: Once your document is transcribed and summarized, you can use our interactive interface to ask questions and get answers directly from the document's content.</p>

              <p><strong>Q: What file formats are supported by AXN.ai?</strong></p>
              <p>A: We support a variety of file formats including audio files, videos, PDFs, Word documents, and more.</p>

              <p><strong>Q: Is AXN.ai accessible to individuals with disabilities?</strong></p>
              <p>A: Yes, inclusivity is a priority for AXN.ai, and we strive to make our services accessible to individuals with disabilities.</p>

              <p><strong>Q: In which languages can AXN.ai transcribe content?</strong></p>
              <p>A: AXN.ai's transcription service supports over 130 languages, demonstrating our commitment to serving a global user base.</p>

              <p><strong>Q: How do I start using AXN.ai?</strong></p>
              <p>A: Simply register on our website to create an account with AXN.ai. Once you've signed up, you'll have access to all our features via the user dashboard.</p>

              <p><strong>Q: How does AXN.ai secure my data?</strong></p>
              <p>A: Data security is our top priority. AXN.ai uses state-of-the-art encryption and security practices to ensure your information is protected against unauthorized access.</p>

              <p><strong>Q: What kind of customer support does AXN.ai offer?</strong></p>
              <p>A: We offer comprehensive customer support, including FAQs, a help center, email support, and live chat to assist you with any questions or issues you may encounter.</p>

              <p>These fundamental FAQs aim to introduce new users to AXN.ai and provide clarity on the most essential aspects of our platform. If you have more detailed or specific questions, our customer support team is ready to assist you further.</p>
            </div>
          </section>
          
          <section>
            <h3>Support and Feedback</h3>
            <p>If you need assistance, submit a support ticket for a prompt response. Your feedback is invaluable to us; share your experiences to help us improve.</p>
          </section>
          
          <section>
            <h3>Contact Information</h3>
            <p>Reach out to our support team at support@axn.ai for personalized assistance.</p>
          </section>
          
          <section>
            <h3>Community Engagement</h3>
            <p>Join our forum to connect with other users, share insights, and discover tips.</p>
          </section>
          
          <section>
            <h3>Success Stories</h3>
            <p>Learn how others have transformed their workflows with AXN.ai.</p>
          </section>
          
          <section>
            <h3>Expert Tips</h3>
            <p>Unlock the full potential of AXN.ai with advanced tips and tricks for power users.</p>
          </section>
          
          <section>
            <h3>Pro Tips</h3>
            <ul>
              <li>Efficiently organize and prioritize your projects and tasks.</li>
              <li>Leverage Hannah's extensive language support for global communication.</li>
              <li>Incorporate Alice and Hannah into your daily routines for enhanced productivity.</li>
              <li>Explore the full suite of AXN.ai tools for comprehensive solutions.</li>
              <li>Adopt a mindset of continuous improvement through insights gained from usage patterns.</li>
              <li>Trust in our robust security measures to protect your data.</li>
              <li>Your feedback is crucial in shaping the future of AXN.ai.</li>
            </ul>
          </section>
    </div>
  );
};

export default HelpContent;
