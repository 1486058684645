import React, { useEffect, useRef, useState } from 'react';
import useInView from '../../../components/inView/useInView';

const lines = [
  "Where Every File Becomes A Conversation, And Every Piece Of Data Tells A Story.",
  "Revolutionize Your Workday – Discover AI, Reimagined.",
  "Summarize, Translate, Transform – All with AXN. ai.",
  "The Benchmark for Brilliance – Trusted by Industry Leaders.",
  "Language is No Barrier – Chat with Hannah in 100+ Languages.",
  "Meetings Made Easy – Alice Attends, So You Don't Have To."
];

const HomeBanner = ({ isDarkMode }) => {
  const [setRef, inView] = useInView({
    threshold: 0.1, // Trigger when the section is at least 10% in view
  });
  const [currentLine, setCurrentLine] = useState(0);
  const svgContainerRef = useRef(null); // Ref for the globe video container

  useEffect(() => {
    // Rotate banner text every 5 seconds
    const interval = setInterval(() => {
      setCurrentLine((prevLine) => (prevLine + 1) % lines.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          observer.disconnect(); // Disconnect observer when the element is visible
        }
      },
      { threshold: 0.5 }
    );

    if (svgContainerRef.current) {
      observer.observe(svgContainerRef.current);
    }

    return () => {
      if (observer) observer.disconnect();
    };
  }, []);

  return (
    <section className={`Home-Banner ${inView ? 'in-view' : ''}`} ref={setRef}>
      <div className="home-banner">
        <div className="row">
          {/* Left Side Content */}
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <div className="left">
              {lines.map((line, index) => (
                <h1
                  key={index}
                  className={`banner-text ${currentLine === index ? 'visible' : ''}`}
                >
                  {line}
                </h1>
              ))}
            </div>
          </div>

          {/* Right Side Globe Video */}
          <div className="col-md-6">
            <div className="right globe-gif-container" ref={svgContainerRef}>
              <video
                key={isDarkMode ? 'dark-mode' : 'light-mode'} // Forces re-render on mode change
                autoPlay
                loop
                muted
                playsInline
                className="globe-video img-fluid"
                preload="auto"
              >
                <source
                  src={
                    isDarkMode
                      ? 'assets/video/globe_dark.webm'
                      : 'assets/video/globe_light.webm'
                  }
                  type="video/webm"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeBanner;
