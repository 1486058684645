import React from "react";

const TwoStepsRlCard = (props) => {

    return (
        <div className="box-shadow-steps card border-r-15 border-0 p-4 my-4">
            <div className="row wrap-reverse align-items-center ">
            <div className="col-md-6">
                <div className="right">
                <video autoPlay muted loop preload="auto" className="card-video">
                        <source src= {props.vid} type="video/mp4" />
                        Your browser does not support the video tag.
                        </video>
                </div>
            </div>
            <div className="col-md-6">
                <div className="left ps-5">
                    <h3>{props.heading}</h3>
                    <p>{props.desc}</p>
                </div>
            </div>
        </div>
        </div>
    );

}

export default TwoStepsRlCard;

